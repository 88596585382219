<template>
 <a-popover v-model="visible" :content="content" :placement="placement" :title="title && title" :trigger="action"
            class="popper-default">
  <template v-if="title" v-slot:title>
   <Title>{{ title }}</Title>
  </template>
  <template v-slot:content>
   <Content>
    <slot name="content">
     {{ content }}
    </slot>
   </Content>
  </template>
  <slot></slot>
 </a-popover>
</template>

<script>
import {Content, Title} from './style';
import VueTypes from 'vue-types';
import {defineComponent} from 'vue';

export default defineComponent({
 name: 'DefaultPopup',
 props: {
  placement: VueTypes.string.def('bottom'),
  title: VueTypes.string,
  class: VueTypes.string,
  content: VueTypes.string,
  action: VueTypes.string.def('hover'),
 },
 data() {
  return {
   visible: false,
   className: this.class,
  };
 },
 components: {
  Content,
  Title,
 },
});
</script>
<style>
@import './style.css';

.ant-popover-inner {
 width: auto !important;
}
</style>
